import { Component, OnInit, ViewEncapsulation, Input, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs';
declare const top: any
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, ApiServ, LoaderServ, InitServ, UtilServ } from '../../Services';
import { Router } from '@angular/router';
import { IS_DEV } from 'src/app/Constants';

@Component({
	selector: 'bk-lead-form',
	templateUrl: './LeadForm.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class LeadFormComponent implements OnInit {
	// Variables
	leadForm: FormGroup;
	@Input() secId: string = '';
	pageSett: any;
	parentPageSett: any;
	// Section fields
	section: any = {
		form: null,
		reviews: null,
		faq: null,
		media: null
	}
	pagePerm: boolean = true;
	loaderId: string = 'lead-form-loader';
	customFields: any;

	constructor(@Self() private destroy: NgOnDestroy, private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, private apiServ: ApiServ, private loader: LoaderServ, public initServ: InitServ, private toastr: ToastrService, private cDRef: ChangeDetectorRef, private utilServ: UtilServ, private router: Router) {
		// Build lead form
		this.leadForm = this.frmBldr.group({ custom_fields: this.frmBldr.group({}), lead_form:'' });
		if(this.utilServ.bookingOrLeadFormStatus('lead_form')){
			// Get the lead form custom fields
			this.getLeadFormFields();
		} else {
			this.router.navigate(['/']);
		}
	}

	ngOnInit(): void {
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.parentPageSett = this.rcServ.pageData.settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	// convenience getter for easy access to form fields
	get f() : { [key: string]: AbstractControl } {
		return (<FormGroup>this.leadForm.get('custom_fields')).controls;
	}
	/**
	 * Get the lead form custom fields
	 */
	public getLeadFormFields(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		this.apiServ.callApi('GET', 'LeadForm').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'leadForm'));
	}
	/**
	 * Build lead form fields
	 */
	private buildLeadForm(): void {
		if (this.customFields && (this.customFields).length > 0) {
			for(let customField of this.customFields) {
				if(customField && customField.custom_fields && (customField.custom_fields).length > 0){
					for(let field of customField.custom_fields) {
						if(field){
							if(field.field_type != 'Note') {
								let fieldName: string = customField._id + '_' + field._id;
								let formGroup: any = <FormGroup>this.leadForm.controls['custom_fields'];
								if(field.field_type == 'Checkbox'){
									if(field.options && (field.options).length > 0){
										formGroup.addControl(fieldName, new FormGroup({}));
										for(let option of field.options) {
											if(option){
												(<FormGroup>formGroup.controls[fieldName]).addControl(option, new FormControl(false));
											}
										}
									}
								} else {
									if (field.is_required) {
										formGroup.addControl(fieldName, new FormControl('', Validators.required));
									} else {
										formGroup.addControl(fieldName, new FormControl(''));
									}
								}
							}
						}
					}
				}
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Checkbox as touched
	 * @param fieldId Field id
	 * @param option option name
	 */
	public checkboxTouched(fieldId: string, option: any): void {
		let controlGroup: any = this.f[fieldId];
		controlGroup.controls[option].markAsTouched();
	}
	/**
	 * All checkbox field is touched or not.
	 * @param fieldId Field id
	 * @returns boolean
	 */
	public allCheckboxTouched(fieldId: string): boolean {
		let controlGroup: any = this.f[fieldId];
		let status: boolean = false;
		for(let option in controlGroup.value) {
			if (controlGroup.controls[option].touched) {
				status = true;
				break;
			}
		}
		return status;
	}
	/**
	 * Check the checkbox is valid or not
	 * @param fieldId field id
	 * @returns boolean
	 */
	public checkboxValid(fieldId: string): boolean {
		let controlGroup: any = this.f[fieldId];
		let status: boolean = false;
		for(let option in controlGroup.value) {
			if (controlGroup.controls[option].value) {
				status = true;
				break;
			}
		}
		return status;
	}
	/**
	 * Check the all checkbox is valid.
	 * @returns boolean
	 */
	private validAllCheckbox(): boolean {
		if (this.customFields && (this.customFields).length > 0) {
			for(let customField of this.customFields) {
				if (customField.custom_fields && (customField.custom_fields).length > 0) {
					for(let field of customField.custom_fields) {
						if (field && field.field_type == 'Checkbox' && field.is_required) {
							if(field.options && (field.options).length > 0){
								for(let option of field.options) {
									if(option){
										this.checkboxTouched(customField._id+'_'+field._id, option);
									}
								}
							}
							if (!this.checkboxValid(customField._id+'_'+field._id)) {
								return false;
							}
						}
					}
				}
			}
		}
		return true;
	}
	/**
	 * Submit form
	 */
	public submitForm(): any {
		if(this.leadForm.controls['lead_form'].value != ''){
			if(IS_DEV){ console.log('There is an issue with lead form.') }
			return false;
		}
		if (this.leadForm.valid && this.validAllCheckbox()) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId);
			this.apiServ.callApi('POST', 'CustomerLead', this.leadForm.value).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'save'));
		} else {
			this.validAllCheckbox();
			for(var i in this.f) {
				this.f[i].markAsTouched();
			}
			for(var i in this.f) {
				if(this.f[i].invalid){
					// Call function to scroll to specific element
					this.utilServ.scrollToSpecificEle(i);
					break;
				}
			}
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * Redirect screen
	 * @param res
	 */
	private redirectScreen(): void {
		let redirectTo = 'none';
		let redirectUrl;
		if(this.parentPageSett && this.parentPageSett.where_should_redirect && this.parentPageSett.redirect_val){
			redirectTo = this.parentPageSett.where_should_redirect;
			redirectUrl = this.parentPageSett.redirect_val;
		}
		let gotoLinkUrl: any = null;
		switch (redirectTo) {
			case "link":
				let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
				this.redirect(linkUrl);
				break;
			case "page":
				gotoLinkUrl = this.utilServ.getAfterSuccessRedirection(redirectUrl, this.loaderId);
				if(gotoLinkUrl){
					this.redirect(this.utilServ.generateLink(gotoLinkUrl));
				}
				break;
			default:
				this.leadForm.removeControl('custom_fields');
				this.leadForm.addControl('custom_fields', this.frmBldr.group({}));
				this.buildLeadForm();
				break;
		}
	}
	/**
	* Change the browser url to desired url
	*/
	public redirect(link:any){
		try{
			top.window.location.href = link;
		} catch(err){
			// this.bookingCnfrmPopup(data);
		}
	}
	/**
	 * On result callback method
	 * @param res API res
	 * @param type API types: gallery
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		switch(type){
			case 'leadForm':
				if(this.apiServ.checkAPIRes(res)){
					this.customFields = res.data;
					if(this.customFields){
						this.buildLeadForm()
					}
				}
			break;
			case 'save':
				if(this.apiServ.checkAPIRes(res)){
					this.toastr.success(this.initServ.appStr.toastr.leadSuccess);
					// Redirect screen
					this.redirectScreen();
				} else {
					if(res && res.message){
						this.toastr.error(res.message);
					}
				}
			break;
		}
		this.loader.hide(this.loaderId)
	}
}
