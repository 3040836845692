import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InitServ, NgOnDestroy, RenderComponentServ, SectionServ, UtilServ } from '../../Services';
@Component({
	selector: 'bk-embed-lead-form',
	template: `<div [id]="secId" class="bkt-section" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && embedFormUrl">
		<iframe id="bkLeadIframe" [src]='embedFormUrl' style="border:none;" height="1200" width="100%" scrolling="no"></iframe>
		<script [src]='embedJsUrl' defer></script>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class EmbedLeadFormComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	embedFormUrl:any;
	embedJsUrl:any;
	varId: any;
	sectionData: any;
	// eslint-disable-next-line max-params
	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private initServ: InitServ, public sanitizer: DomSanitizer, private utilServ: UtilServ) {
		this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.initServ.appData.primary_custom_domain + '/resources/embed.js');
	}

	ngOnInit() {
		this.setSecData();
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.varId = this.pageSett[this.secId].variation_id;
			let secElem : any = null;
			if(this.sectionData && this.sectionData.sections){
				secElem = this.sectionData.sections[this.secId];
			}
			let url: any = null;
			if(secElem && secElem.form){
				let urlStatus: any = this.secServ.checkEleStatus(this.pageSett, secElem.form);
				if(urlStatus){
					url = this.pageSett[secElem.form]?.url;
				}
			}
			if(url){
				let formUrl = this.initServ.baseUrl + '/leads/form/' + url + '?embed=true';
				this.embedFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(formUrl);
				this.utilServ.setHeight(1000, 'bkLeadIframe');
				this.utilServ.setHeight(3000, 'bkLeadIframe');
				this.utilServ.setHeight(5000, 'bkLeadIframe');
				this.utilServ.setHeight(8000, 'bkLeadIframe');
			}
		}
	}
	private setSecData(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
	}
}
