<div [id]="secId" class="tjs-contact-page py-80 tjs-bg-style position-relative" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" class="tjs-opacity--bg"></div>
	<div class="container">
		<div class="row" [ngClass]="{'justify-content-center': !(section?.reviews && section?.faq)}">
			<div class="col-12 col-sm-12 col-md-12 col-lg-8 mb-30 mb-lg-0 position-relative" *ngIf="section?.form">
				<ng-container *ngIf="customFields && (customFields).length > 0; else noFields">
					<!-- Form -->
					<form class="bk-form" [formGroup]="leadForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()">
						<div class="card rounded-5 border-imp" [id]="section?.form?.id">
							<div class="card-body py-0" formGroupName="custom_fields">
								<div class="lead-custom-fields">
									<div class="border-bottom px-sm-30 pt-35 tjs-border-last-0 bk-form-border" *ngFor="let customField of customFields;let last=last;trackBy: secServ.trackByFnIndex" [class.pb-20]="last">
										<!-- Name -->
										<div class="mb-25">
											<h4 class="mb-0 bk-form-title">{{customField?.name | translate}}</h4>
										</div>
										<ng-container *ngIf="customField && customField?.custom_fields && (customField?.custom_fields).length > 0">
											<div class="mb-30" *ngFor="let field of customField?.custom_fields;trackBy: secServ.trackByFnIndex">
												<!-- Label -->
												<label class="form-label bk-form-label">{{field?.name | translate}}</label>
												<bk-tooltip [inheritStyle]="true" class="bk-form-label" *ngIf="field?.help_text" customClass="bk-tooltip" content="{{field?.help_text | translate}}"></bk-tooltip>
												<!-- Fields -->
												<ng-container [ngSwitch]="field?.field_type">
													<!-- Text -->
													<ng-container *ngSwitchCase="'Text'">
														<input [id]="customField?._id+'_'+field?._id" class="form-control" [ngClass]="{'is-invalid': f[customField?._id+'_'+field?._id]?.touched && f[customField._id+'_'+field?._id]?.errors}" attr.placeholder="{{field?.placeholder | translate}}" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" type="text">
													</ng-container>
													<!-- Text area -->
													<ng-container *ngSwitchCase="'Text Area'">
														<textarea [id]="customField?._id+'_'+field?._id" [ngClass]="{'is-invalid': f[customField?._id+'_'+field?._id]?.touched && f[customField._id+'_'+field?._id]?.errors}" class="form-control" rows="5" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" attr.placeholder="{{field?.placeholder | translate}}"></textarea>
													</ng-container>
													<!-- Radio buttons -->
													<ng-container *ngSwitchCase="'Radio'">
														<div class="bk-radio" [id]="customField?._id+'_'+field?._id" *ngIf="field?.options && (field?.options).length > 0">
															<ng-container *ngFor="let option of field?.options;trackBy: secServ.trackByFnIndex">
																<span class="form-check form-check-inline mb-10 mb-lg-0 mt-5" *ngIf="option">
																	<input id="{{customField?._id}}_{{field?._id}}_{{option}}" class="form-check-input" name="{{customField?._id}}_{{field?._id}}" formControlName="{{customField?._id}}_{{field?._id}}" type="radio" value="{{option}}">
																	<label class="form-check-label bk-form-check-label" for="{{customField?._id}}_{{field?._id}}_{{option}}">{{option | translate}}</label>
																</span>
															</ng-container>
														</div>
													</ng-container>
													<!-- Checkbox -->
													<ng-container *ngSwitchCase="'Checkbox'">
														<div class="bk-checkbox" [id]="customField?._id+'_'+field?._id" *ngIf="field?.options && (field?.options).length > 0" formGroupName="{{customField?._id}}_{{field?._id}}">
															<ng-container *ngFor="let option of field?.options;trackBy: secServ.trackByFnIndex">
																<div class="form-check form-check-inline mb-10 mt-5" *ngIf="option">
																	<input id="{{customField?._id}}_{{field?._id}}_{{option}}" class="form-check-input" type="checkbox" formControlName="{{option}}" checked="checked" (change)="checkboxTouched(customField?._id+'_'+field?._id, option)">
																	<label class="form-check-label bk-form-check-label" for="{{customField?._id}}_{{field?._id}}_{{option}}">{{option | translate}}</label>
																</div>
															</ng-container>
														</div>
													</ng-container>
													<!-- Dropdown/select list -->
													<ng-container *ngSwitchCase="'Dropdown'">
														<select class="form-select bk-select" [id]="customField?._id+'_'+field?._id" [ngClass]="{'is-invalid': f[customField?._id+'_'+field?._id]?.touched && f[customField._id+'_'+field?._id]?.errors}" *ngIf="field?.options && (field?.options).length > 0" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}">
															<option value="">{{'Select Option' | translate}}</option>
															<ng-container *ngFor="let option of field?.options;trackBy: secServ.trackByFnIndex">
																<option value="{{option}}">{{option | translate}}</option>
															</ng-container>
														</select>
													</ng-container>
													<!-- Simple text/message -->
													<ng-container *ngSwitchCase="'Note'">
														<span class="lead_note bk-note" [innerHTML]="field?.note_text | translate | safeHtml"></span>
													</ng-container>
												</ng-container>
												<!-- Error -->
												<ng-container *ngIf="field.is_required">
													<ng-container *ngIf="field?.field_type == 'Checkbox'; else formError">
														<div class="tjs-error" *ngIf="!checkboxValid(customField?._id+'_'+field?._id) && allCheckboxTouched(customField?._id+'_'+field?._id)">
															<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.required | translate}}
														</div>
													</ng-container>
													<ng-template #formError>
														<div class="tjs-error" *ngIf="f[customField?._id+'_'+field?._id]?.touched && f[customField?._id+'_'+field?._id]?.errors?.required">
															<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.required | translate}}
														</div>
													</ng-template>
												</ng-container>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
						<!-- Cookies -->
						<div class="tjs-privacy-text mt-10 text-muted" *ngIf="section?.form?.cookie_content">
							<span class="bk-cookie fs-14" [id]="section?.form?.cookie_content_id" [innerHTML]="section?.form?.cookie_content | safeHtml"></span>
						</div>
						<!-- Hidden input -->
						<div class="d-none"><input type="text" class="form-control bk-input" formControlName="lead_form" /></div>
						<!-- Submit button -->
						<bk-navigate *ngIf="section?.form?.button" [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.form?.button?.content" customClass="btn btn-primary btn-lg fs-20 border-transparent" essentialClass="w-100 mt-30 bk-form-btn"></bk-navigate>
					</form>
				</ng-container>
				<ng-template #noFields>
					<div class="card rounded-5 border" [id]="section?.form?.id">
						<div class="card-body text-center">
							<h4 class="mb-0 fw-bold">{{initServ?.appStr?.errorMsg?.noLeadForm | translate}}</h4>
						</div>
					</div>
				</ng-template>
				<!-- Loader -->
				<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
			</div>
			<!-- Side panel widgets-->
			<div class="col-12 col-lg-4" *ngIf="section?.reviews || section?.faq">
				<!-- Live reviews -->
				<bk-live-review *ngIf="section?.reviews" [section]="section?.reviews" pageType="lead"></bk-live-review>
				<!-- FAQ -->
				<bk-faq-list *ngIf="section?.faq" [section]="section?.faq" pageType="lead"></bk-faq-list>
			</div>
		</div>
	</div>
</div>
